<template>
    <div class="row justify-content-center">
      <div class="card col-sm-12 col-md-12 p-1">
        <div>
          <div class="title head">
            <p class="h2" style="color: #558cef">ตรวจสอบประจำปี</p>
          </div>
          <hr />
          <div>
            <div class="row justify-content-end">
              <!-- <b-form-group
                class="col-md-3"
                label-cols="3"
                label-cols-lg="3"
                label="ปีงบประมาณ:"
              >
                <b-form-select v-model="ree" :options="rees" />
              </b-form-group> -->
              <!-- search input -->
  
              <b-form-group class="col-12 col-md-6">
                <div class="d-flex align-items-center">
                  <label class="mr-1">ค้นหา</label>
                  <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
                  <div>
                    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <b-button variant="outline-primary"><feather-icon icon="ShareIcon" class="mr-50" />Export
                          <feather-icon icon="ChevronDownIcon" class="mr-50" /></b-button>
                      </template>
  
                      <b-dropdown-item @click="exportExcel">
                        <feather-icon icon="FileIcon" class="mr-50" />
                        <span>Excel</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </b-form-group>
  
              <!-- <b-form-group
                class="col-md-3"
                label-cols="3"
                label-cols-lg="3"
                label="ประเภทพัสดุ:"
              >
                <b-form-select v-model="type" :options="types" />
              </b-form-group>             -->
            </div>
          </div>
  
          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table :columns="columns" :rows="rows" :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }" :pagination-options="{
    enabled: true,
    perPage: pageLength,
  }" :line-numbers="true" :sort-options="{
    enabled: true,
    initialSortBy: { field: 'check_code', type: 'desc' },
  }">
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                  <b-avatar :src="props.row.avatar" class="mx-1" />
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>
  
                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <span>
                    <b-button variant="outline-primary" class="btn-icon mr-1"
                      :to="{ name: 'inannualcheck2_neits', params: { id: props.row } }">
                      <feather-icon icon="EyeIcon" size="16" class="mx-1" />
                    </b-button>
                  </span>
                </span>
  
                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
  
              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select v-model="pageLength" :options="['5', '10', '15', '25', '50', '100']" class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage(value))" />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination :value="1" :total-rows="total" :per-page="pageLength" first-number last-number
                      align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                      @input="handlePagechange($event)">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue';
  import { BButton, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem } from 'bootstrap-vue';
  import { VueGoodTable } from 'vue-good-table';
  import store from '@/store/index';
  import axios from 'axios';
  import API from '@/views/connectDB/condb.js';
  import { saveExcel } from '@progress/kendo-vue-excel-export';
  
  export default {
    components: {
      BButton,
      BCardCode,
      VueGoodTable,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdown,
      BDropdownItem,
    },
  
    data() {
      return {
        show: false,
        pageLength: 50,
        currentPage: 1,
        total: '',
        ree: '',
        rees: [
          { value: '', text: 'เลือกปีงบประมาณ:' },
          { value: '2559', text: '2559' },
          { value: '2560', text: '2560' },
          { value: '2561', text: '2561' },
        ],
        columns: [
          {
            label: 'เริ่มวันที่',
            field: 'start_date_th',
          },
          {
            label: 'ถึงวันที่',
            field: 'stop_date_th',
          },
          {
            label: 'ปีงบประมาณ',
            field: 'budget_year',
          },
          {
            label: 'รหัส',
            field: 'check_code',
          },
          {
            label: 'กลุ่มงาน',
            field: 'work_gname',
          },
          {
            label: 'รายละเอียด',
            field: 'information',
          },
  
          {
            label: 'สถานะ',
            field: 'check_status',
          },
          {
            label: 'ตรวจสอบ',
            field: 'action',
          },
        ],
        rows: [],
        searchTerm: '',
        month: [
          '',
          'มกราคม',
          'กุมภาพันธ์ ',
          'มีนาคม ',
          'เมษายน ',
          'พฤษภาคม ',
          'มิถุนายน',
          'กรกฎาคม',
          'สิงหาคม',
          'กันยายน',
          'ตุลาคม',
          'พฤศจิกายน',
          'ธันวาคม',
        ],
      };
    },
    created() {
      this.get_data_table();
      //this.checkLogout();
    },
    methods: {
      async checkLogout() {
        console.log('ตรวจสอบออกจากระบบ');
        const { username } = await JSON.parse(localStorage.getItem('username_type'));
        console.log(username);
        axios
          .get('https://eoffice.niets.or.th/verify/auth', {
            headers: {
              'px-auth-token': `${username}`,
            },
          })
          .then((response) => {
            console.log(response.status);
            if (response.status === 200) {
              console.log(' User อยู่ในระบบ');
            }
          })
          .catch((res) => {
            const { response } = res;
            console.log(response);
            if (response.status != 200) {
              console.log(' User ออกจากระบบเเล้ว');
              this.$router.push({ name: 'logoutUser' });
            }
          });
        console.log('-------------------------');
      },
      exportExcel() {
        saveExcel({
          data: this.rows,
          fileName: 'ตรวจสอบประจำปี',
          columns: [
            {
              title: 'ตรวจสอบประจำปี',
              headerCellOptions: { textAlign: 'center' },
              children: [
                {
                  title: 'เริ่มวันที่',
                  field: 'start_date_th',
                },
                {
                  title: 'ถึงวันที่',
                  field: 'stop_date_th',
                },
                {
                  title: 'ปีงบประมาณ',
                  field: 'budget_year',
                },
                {
                  title: 'รหัส',
                  field: 'check_code',
                },
                {
                  title: 'กลุ่มงาน',
                  field: 'work_gname',
                },
                {
                  title: 'รายละเอียด',
                  field: 'information',
                },
  
                {
                  title: 'สถานะ',
                  field: 'check_status',
                },
              ],
            },
          ],
        });
      },
      handlePagechange(event) {
        this.currentPage = event;
        this.get_data_table();
      },
      handleChangePage(event) {
        this.pageLength = event;
        this.get_data_table();
      },
      async get_data_table() {
        this.show = true;
        const { access_token } = await this.access_token();
        const url = `${API}examineList?_page=${this.currentPage}&_limit=${this.pageLength}&_sort=-1`;
        const head = {
          headers: {
            Authorization: await access_token,
          },
        };
        const res = await axios.get(url, head);
        console.log(res.data.message)
        this.rows = res.data.message.data.map((element) => {
          return {
            ...element,
            start_date_th: ` ${parseInt(element.start_date.split('-')[2])} ${this.month[parseInt(element.start_date.split('-')[1])]} ${Number(element.start_date.split('-')[0]) + 543
              }`,
            stop_date_th: ` ${parseInt(element.stop_date.split('-')[2])} ${this.month[parseInt(element.stop_date.split('-')[1])]} ${Number(element.stop_date.split('-')[0]) + 543
              }`,
          };
        });
        this.total = res.data.message.total;
        this.show = false;
      },
      async access_token() {
        return (
          await axios.post(`${API}permit`, '', {
            headers: {
              Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
            },
          })
        ).data.message;
      },
    },
  };
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-good-table.scss';
  </style>
  